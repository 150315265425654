<template>
    <LiefengContent>
        <template #title>首页</template>
        <template #contentArea>
            <div class="card header">
                <div class="box">
                    <img src="/images/census_calendar.png" alt="">
                    今天是:<span class="textact">2022年03月24日</span> 
                </div>
                <div class="box">
                    <img src="/images/census_online.png" alt="">
                    随约已上线：<span class="textact">1063天</span> 
                </div>
            </div>

            <div class="card_list">
                <div class="card_item first"></div>
                <div class="card_item second"></div>
                <div class="card_item third"></div>
            </div>

            <div class="card_content">
                <div class="card_item content"></div>
                <div class="card_item chart"></div>
            </div>
        </template>
    </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import Veline from "v-charts/lib/line.common"
export default {
    components: {
        LiefengContent,
        LiefengTable,
        Veline,
    },
    data() {
        return {
            
        }
    },
    created(){
        this.$Message.loading({
            content: "获取数据中...",
            duration: 0,
        });
        setTimeout(() => {
            this.$Message.destroy();
        },500)
    },
    methods: {
    },
    
    
}
</script>
    
<style scoped lang='less'>
/deep/#contentarea {
    background-color: #f1f1f1;
    .card {
        margin-top: 15px;
        width: 100%;
        background: #ffffff;
        border-radius: 10px 10px 10px 10px;
        opacity: 1;
        padding: 20px;
        box-sizing: border-box;
    }
    .header{
        display: flex;
        .box{
            width: 500px;
            vertical-align: middle;
            height: 40px;
            line-height: 40px;
            font-size: 18px;
            .textact{
                vertical-align: middle;
                font-size: 24px;
                color: #336DFF;
                display: inline-block;
            }
            img{
                width: 40px;
            }
        }
    }
    .card_list{
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        .card_item{
            width: calc( (100%-40px) / 3 );  
            // width: 100%;
            // flex:1;
            height: 300px;
            border-radius: 10px 10px 10px 10px;
            margin-right: 20px;
        }
        .first{
            background: #8593F2;
        }
        .second{
            background: #BD8DEF;
        }
        .third{
            background: #FEA898;
            margin-right: 0 !important;
        }
    }
    .card_content{
        
        .card_item{
            height: 500px;
            background-color: #fff;
            border-radius: 10px 10px 10px 10px;
        }
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        .content{
            width: calc( (100%-40px) / 3 * 2 + 20px );
            margin-right: 20px;
        }
        .chart{
            width: calc( (100%-40px) / 3 )
        }
    }
}
</style>